import { RaButton } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'

export const MobileCardsCarouselControls = ({
  activeSlide,
  slideCount,
  slidePrev,
  slideNext,
}) => {
  return (
    <div className="mobile-cards-carousel__controls">
      <RaButton
        variant="icon"
        icon={ICONS.MOBILE_SLIDE_PREV}
        onClick={slidePrev}
        disabled={activeSlide === 0}
      />
      <RaButton
        variant="icon"
        icon={ICONS.MOBILE_SLIDE_NEXT}
        onClick={slideNext}
        disabled={activeSlide === slideCount - 1}
      />
    </div>
  )
}
