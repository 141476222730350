'use client'
import { useEffect, useRef, useState } from 'react'
import { Loop } from '@utilities/helpers/core.helpers'
import Swiper from 'swiper'

import 'swiper/scss/a11y'

import { MobileCardsCarouselControls } from './MobileCardsCarouselControls'

import './mobile-cards-carousel.styles.scss'
import 'swiper/scss'

interface MobileCardsCarouselProps {
  slides: React.ReactNode[]
}

export const MobileCardsCarousel = ({ slides }: MobileCardsCarouselProps) => {
  const swiperRef = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        speed: 350,
        spaceBetween: 24,
        grabCursor: true,
        slidesPerView: 'auto',
        on: {
          activeIndexChange: ({ activeIndex }) => setActiveSlide(activeIndex),
        },
      })
    }
  }, [swiperRef])

  const slideCount = slides.length

  const slideNext = () => {
    swiperRef.current.swiper.slideNext()
  }
  const slidePrev = () => {
    swiperRef.current.swiper.slidePrev()
  }

  return (
    <div className={'mobile-cards-carousel'}>
      <div
        className="swiper"
        ref={swiperRef}>
        <div className="swiper-wrapper">
          {Loop(slides, (slide, i) => (
            <div
              key={i}
              className="swiper-slide">
              {slide}
            </div>
          ))}
        </div>
        {slideCount > 1 && (
          <MobileCardsCarouselControls
            activeSlide={activeSlide}
            slideCount={slides.length}
            slideNext={slideNext}
            slidePrev={slidePrev}
          />
        )}
      </div>
    </div>
  )
}
