'use client'
import { RaCardTrip } from '@components/cards/RaCardTrip/RaCardTrip'
import { RaCardInspiration } from '@components/cards/RaCardInspiration/RaCardInspiration'
import { RaCardCTA } from '@components/cards/RaCardCTA/RaCardCTA'
import { RaCardProduct } from '@components/cards/RaCardProduct/RaCardProduct'
import { RaCardBlog } from '@components/cards/RaCardBlog/RaCardBlog'
import { RaCardRegion } from '@components/cards/RaCardRegion/RaCardRegion'
import { RaCardCampaign } from '@components/cards/RaCardCampaign/RaCardCampaign'
import { RaCardTravelplan } from '@components/cards/RaCardTravelplan/RaCardTravelplan'
import { RaCardNews } from '@components/cards/RaCardNews/RaCardNews'
import { RaCardAdditionalService } from '@components/cards/RaCardAdditionalService/RaCardAdditionalService'
import { RaCardTakeout } from '@components/cards/RaCardTakeout/RaCardTakeout'
import { RaCardBlogCategory } from '@components/cards/RaCardBlogCategory/RaCardBlogCategory'

interface CardLazyWraperProps {
  props: any
  renderCard:
    | 'trip'
    | 'blog'
    | 'blog-category'
    | 'news'
    | 'product'
    | 'cta'
    | 'travelplan'
    | 'takeout'
    | 'inspiration'
    | 'region'
    | 'campaign'
    | 'additional-service'
}

export const CardLazyWrapper = ({ renderCard, props }: CardLazyWraperProps) => {
  switch (renderCard) {
    case 'trip':
      return <RaCardTrip {...props} />

    case 'blog':
      return <RaCardBlog {...props} />

    case 'blog-category':
      return <RaCardBlogCategory {...props} />

    case 'product':
      return <RaCardProduct {...props} />

    case 'cta':
      return <RaCardCTA {...props} />

    case 'inspiration':
      return <RaCardInspiration {...props} />

    case 'campaign':
      return <RaCardCampaign {...props} />

    case 'region':
      return <RaCardRegion {...props} />

    case 'news':
      return <RaCardNews {...props} />

    case 'travelplan':
      return (
        <RaCardTravelplan
          key={props.index}
          {...props}
        />
      )

    case 'takeout':
      return <RaCardTakeout {...props} />

    case 'additional-service':
      return <RaCardAdditionalService {...props} />

    default:
      return null
  }
}
