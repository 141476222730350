'use client'
import { useEffect, useRef, useState } from 'react'
import { RaButton } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'

import { useUi } from '@contexts/ui.context'

import './ra-stepmap.styles.scss'

interface RaStepmapProps {
  stepmapScript: string
  index: string
}

const EXPORT_CONTAINER_CLASS = '.stepmap_export_container'

export const RaStepmap = ({ stepmapScript, index }: RaStepmapProps) => {
  const containerRef = useRef(null)
  const { showLightbox } = useUi()

  const [showZoomButton, setShowZoomButton] = useState(false)

  const openLightbox = () => {
    const wrapper = containerRef?.current
    const exportDiv = wrapper.querySelector(EXPORT_CONTAINER_CLASS)
    if (exportDiv) {
      const image = exportDiv.querySelector("img[id^='stepmap_image_']")
      if (image) {
        showLightbox(
          <img
            src={image.src}
            alt="Stepmap"
            loading="lazy"
          />
        )
      }
    }
  }

  const onScriptLoaded = () => {
    setTimeout(() => {
      const container = containerRef.current
      if (container) {
        const height = container?.parentElement?.offsetHeight
        const stepmapExportContainer =
          container?.children[1]?.children[0]?.children[1]
        if (stepmapExportContainer) {
          if (height == 0) {
            stepmapExportContainer.style.maxHeight = '500px'
            stepmapExportContainer.style.position = 'relative'
          } else {
            stepmapExportContainer.style.maxHeight = height + 'px'
            stepmapExportContainer.style.position = 'relative'
          }
          setShowZoomButton(true)
        }
      }
    }, 250)
  }

  useEffect(() => {
    if (!stepmapScript) return
    if (stepmapScript.substring(1, 7) !== 'script') return

    const stepmapRegexSrc = RegExp(/(src='|src=")(.*)('>|">)/)
    const stepmapRegexId = RegExp(/(id='|id=")(.*)('\st|"\st|'\sa|"\sa)/)
    const stepmapSrc = stepmapRegexSrc.exec(stepmapScript)?.[2]

    const stepmapId = stepmapRegexId?.exec(stepmapScript)?.[2]
    const stepmapSrcSecured = stepmapSrc?.replace('http://', 'https://')

    const container = containerRef?.current
    const script = document.createElement('script')
    if (!container.children.length) {
      script.id = stepmapId
      script.async = true
      script.src = stepmapSrcSecured
      container.appendChild(script)
      script.addEventListener('load', onScriptLoaded)
    }

    return () => script.removeEventListener('load', onScriptLoaded)
  }, [])

  return (
    <div
      key={index}
      data-key={index}
      className="ra-stepmap"
      onClick={openLightbox}
      ref={containerRef}>
      {showZoomButton && (
        <div className="ra-stepmap__zoom">
          <RaButton
            variant="icon"
            icon={ICONS.ZOOM}
            onClick={openLightbox}
          />
        </div>
      )}
    </div>
  )
}
